<template>
  <div>
    <el-button type="success" :disabled="$store.state.userRoles !== 'ADMIN'" @click="showAddTransactionModal" icon="el-icon-user"
      round>Add Transaction</el-button>
    <article>
      <section>
        <div class="">
          <el-dialog :title="`Add Transaction`" :visible.sync="addTransactionModal" :destroy-on-close="true"
            :before-close="handleClose" style="text-align: left">
            <div v-loading="submitting">
              <el-form :model="transactionForm" :rules="rules" label-position="top" ref="transactionForm" class="demo-transactionForm2">
                <div class="row">
                  <div class="col-md-12">
                      <el-form-item label="Product" prop="productID">
                        <el-select v-model="transactionForm.productID" placeholder="Select">
                          <el-option
                            v-for="item in products"
                            :key="item.ProductID"
                            :label="item.ProductName"
                            :value="item.ProductID">
                          </el-option>
                        </el-select>
                      </el-form-item>
                  </div>
                  <div class="col-md-12">
                      <el-form-item label="Transaction Type" prop="transactionType">
                        <el-select v-model="transactionForm.transactionType" placeholder="Select">
                          <el-option
                            v-for="item in transactionTypes"
                            :key="item.transactionType"
                            :label="item.transactionType"
                            :value="item.transactionType">
                          </el-option>
                        </el-select>
                      </el-form-item>
                  </div>
                  <div class="col-md-12">
                    <el-form-item label="Quantity" prop="quantity">
                      <el-input v-model="transactionForm.quantity" @keyup.enter.native="addTransaction('transactionForm')"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-12">
                    <el-form-item label="Remarks" prop="remarks">
                      <el-input v-model="transactionForm.remarks" @keyup.enter.native="addTransaction('transactionForm')"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <hr />
                <el-form-item class="text-center">
                  <el-button type="success" round @click="addTransaction('transactionForm')">Add Transaction</el-button>
                </el-form-item>
              </el-form>

              <div class="text-center">
                <div class="text-center">
                  <small>Design by <a href="https://ovalspace.co"
                      style="color: black;"><strong>Ovalspace</strong></a></small>
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      isLoading: false,

      addTransactionModal: false,
      editWarehouseModal: false,
      value: "",
      loadingError: false,
      submitting: false,

products: [],

      transactionTypes: [
        {transactionType: 'men'},
        {transactionType: 'rget'},
        {transactionType: 'getrirtmen'},
        {transactionType: 'vocomhhh'},
      ],
      transactionForm: {
          productID: "",
          quantity: "",
          transactionType: "",
          remarks: ""
      },

      rules: {
        productID: [ 
          { required: true, message: "Product is required", trigger: "blur", },
        ],
        quantity: [
          { required: true, message: "Quantity Purchased is required", trigger: "blur" },
        ],
        transactionType: [
          { required: true, message: "Transaction Type is required", trigger: "blur" },
        ],
        remarks: [
          { required: true, message: "Remarks is required", trigger: "blur" },
        ]
      },
    };
  },

  methods: {
    handleClose(done) {
      done();
    },

    showAddTransactionModal() {
      this.addTransactionModal = true;
      this.getProducts();
      this.transactionForm.productID = "";
      this.transactionForm.quantity = "";
      this.transactionForm.remarks = "";
    },

    async addTransaction(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.submitting = true;
            let response = await this.$http.post(`transactions`, {
              productID: this.transactionForm.productID,
              quantity: this.transactionForm.quantity,
              transactionType: this.transactionForm.transactionType,
              remarks: this.transactionForm.remarks,
              addedBy: this.$store.state.userId,
            });
            if (
              response.data.success &&
              response.data.message == "TRANSACTION_CREATED_SUCCESSFULLY"
            ) {
              this.$refs[formName].resetFields();
              this.$emit("re-fresh");
              this.$notify({
                title: "Success",
                message: "Transactions added successfully",
                type: "success",
              });
              this.addTransactionModal = false;
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            this.$notify({
              title: "Unable to Add Transaction",
              message: "An unexpected Error occurred, please try again",
              type: "error",
            });
          } finally {
            this.submitting = false;
          }
        } else {
          return false;
        }
      });
    },
      async getProducts() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
          let request = await this.$http.get(
            `products`
          );
          if (request.data.success) {
            this.products = request.data.products;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch products now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },
  },
};
</script>

<style></style>